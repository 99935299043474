jQuery(function($) {
  $('.dropdown-button').click(function() {
    var bioContent = $(this).next('.bio')
    var bioHeight = bioContent[0].scrollHeight
    console.log(bioContent);

    $(this).next('.bio').toggleClass('active')

    if($(this).next('.bio').hasClass('active')) {
      bioContent.css('max-height', `${bioHeight}px`)
    } else {
      bioContent.css('max-height', `0px`)
    }
  })
})