jQuery(function($) {
  $('.accordion-item--title').click(function() {
    var accordionContent = $(this).next('.accordion-item--content')
    var accordionContentHeight = accordionContent[0].scrollHeight

    $(this).toggleClass('active')

    if($(this).hasClass('active')) {
      accordionContent.css('max-height', `${accordionContentHeight}px`)
    } else {
      accordionContent.css('max-height', `0px`)
    }
  })
})